<template>
  <div class="d-flex flex-column">
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-dialog
      v-if="selected_image && selected_promatItem"
      v-model="showImageDialog"
      scrollable
      width="auto"
    >
      <v-card>
        <v-card-title class="d-flex justify-content-between align-start">
          <span class="text-h5">{{ selected_promatItem?.name }}</span>
          <div>
            <div>
              <span class="text-h6">{{
                selected_promatItem?.grams
                  ? `${selected_promatItem?.grams} ${$t("Grams")}`
                  : ""
              }}</span>
            </div>
            <div>
              <span class="text-h6">{{
                selected_promatItem?.mHeight
                  ? `${selected_promatItem?.mHeight} ${$t("Height")}`
                  : ""
              }}</span>
            </div>
            <div>
              <span class="text-h6">{{
                selected_promatItem?.mWidth
                  ? `${selected_promatItem?.mWidth} ${$t("Width")}`
                  : ""
              }}</span>
            </div>
            <div>
              <span class="text-h6">{{
                selected_promatItem?.mLength
                  ? `${selected_promatItem?.mLength} ${$t("Length")}`
                  : ""
              }}</span>
            </div>
            <div>
              <span class="text-h6">{{
                selected_promatItem?.pro_ean
                  ? `EAN: ${selected_promatItem?.pro_ean}`
                  : ""
              }}</span>
            </div>
          </div>
        </v-card-title>
        <v-card-text style="padding-bottom: 0 !important;">
          <v-container class="text--primary">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img style="max-width: 79%;" :src="selected_image" alt="" />
                <img
                  style="max-width: 20%;"
                  class="ml-1"
                  v-if="selected_promatItem?.eanStaticPhoto"
                  :src="getImageUri(selected_promatItem?.eanStaticPhoto)"
                  alt=""
                />
              </v-col>
            </v-row>
            <v-row v-if="selected_promatItem?.description">
              <v-col cols="12" sm="12" md="12">
                <p class="text-h5 sectionTitle">
                  {{ $t("brands.description") }}
                </p>
                <p class="sectionContents">
                  {{ selected_promatItem?.description }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="selected_promatItem?.salesArguments">
              <v-col cols="12" sm="12" md="12">
                <p class="text-h5 sectionTitle">{{ $t("Sales Arguments") }}</p>
                <div
                  v-html="selected_promatItem?.salesArguments"
                  class="sectionContents text--primary mt-2"
                ></div>
              </v-col>
            </v-row>
            <v-row v-if="selected_promatItem?.executionInstruction">
              <v-col cols="12" sm="12" md="12">
                <p class="text-h5 sectionTitle">
                  {{ $t("Execution Instruction") }}
                </p>
                <div
                  v-html="selected_promatItem?.executionInstruction"
                  class="sectionContents text--primary mt-2"
                ></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <b-alert v-if="!posSession || !salesData || isLoading" show variant="info">
      <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
      cargando datos...
    </b-alert>
    <div
      class="d-flex flex-column mt-2"
      v-else-if="posSession && productsForSales"
    >
      <div
        class="d-flex justify-content-between align-center flex-wrap filter-wrapper mb-4"
      >
        <!-- <v-btn @click="init">Set init !</v-btn> -->
        <b-button
          class="mr-1"
          variant="outline-secondary"
          size="sm"
          @click="onClickBackButton"
        >
          <b-icon icon="arrow-left"></b-icon> volver
        </b-button>
        <div class="d-flex justify-content-center align-center flex-wrap pt-2">
          <v-select
            class="mt-0 pt-0 mr-2 mb-2 brandSelectBox"
            :items="sortByOptions"
            item-text="label"
            item-value="value"
            :label="$t('Sort By')"
            hide-details="auto"
            clearable
            v-model="selectedSortOptions"
            @change="onChangeSortByOptions"
            multiple
          >
          </v-select>
          <v-select
            class="mt-0 pt-0 mr-2 mb-2 brandSelectBox"
            :items="brandsForSales"
            item-text="name"
            item-value="id"
            :label="$t('brands.brand')"
            hide-details="auto"
            clearable
            v-model="filterCriteria['searchByBrandId']"
            @change="onChangeFilterbyBrandId"
            multiple
          >
          </v-select>
          <b-button-group class="flex-wrap mb-2">
            <b-button
              variant="outline filter-button-group"
              size="sm"
              @click="onToogleFilter('PRIORITY_YESANDNO')"
              :class="
                `${
                  Object.keys(this.filterCriteria).includes(
                    'PRIORITY_YESANDNO'
                  ) && this.filterCriteria['PRIORITY_YESANDNO'] === true
                    ? 'filteractive'
                    : ''
                }`
              "
            >
              <b-icon icon="exclamation-lg"></b-icon>
            </b-button>
            <b-button
              variant="outline filter-button-group"
              size="sm"
              style="font-style: italic;font-weight: 800;"
              @click="onToogleFilter('ONLYNOVELTY')"
              :class="
                `${
                  Object.keys(this.filterCriteria).includes('ONLYNOVELTY') &&
                  this.filterCriteria['ONLYNOVELTY'] === true
                    ? 'filteractive'
                    : ''
                }`
              "
            >
              N
            </b-button>
            <b-button
              variant="outline filter-button-group btnred"
              size="sm"
              @click="onToogleFilter('ONLYSALES_INLAST2MONTHS')"
              :class="
                `${
                  Object.keys(this.filterCriteria).includes(
                    'ONLYSALES_INLAST2MONTHS'
                  ) && this.filterCriteria['ONLYSALES_INLAST2MONTHS'] === true
                    ? 'filteractive'
                    : ''
                }`
              "
            >
              <b-icon icon="graph-up"></b-icon>
            </b-button>
            <b-button
              variant="outline filter-button-group btnred"
              size="sm"
              @click="onToogleFilter('ONLYRETURNS_INCURRENTCHART')"
              :class="
                `${
                  Object.keys(this.filterCriteria).includes(
                    'ONLYRETURNS_INCURRENTCHART'
                  ) &&
                  this.filterCriteria['ONLYRETURNS_INCURRENTCHART'] === true
                    ? 'filteractive'
                    : ''
                }`
              "
            >
              <b-icon icon="reply-all-fill"></b-icon>
            </b-button>
            <b-button
              variant="outline filter-button-group btnred"
              size="sm"
              @click="onToogleFilter('WITHSALE_ZERO_INCURRENTCHART')"
              :class="
                `${
                  Object.keys(this.filterCriteria).includes(
                    'WITHSALE_ZERO_INCURRENTCHART'
                  ) &&
                  this.filterCriteria['WITHSALE_ZERO_INCURRENTCHART'] === true
                    ? 'filteractive'
                    : ''
                }`
              "
            >
              <b-icon icon="cart-fill"></b-icon>
            </b-button>
            <b-button
              variant="outline filter-button-group btngreen"
              size="sm"
              @click="onToogleFilter('WITHSALE_GREATERZERO_INCURRENTCHART')"
              :class="
                `${
                  Object.keys(this.filterCriteria).includes(
                    'WITHSALE_GREATERZERO_INCURRENTCHART'
                  ) &&
                  this.filterCriteria['WITHSALE_GREATERZERO_INCURRENTCHART'] ===
                    true
                    ? 'filteractive'
                    : ''
                }`
              "
            >
              <b-icon icon="cart-fill"></b-icon>
            </b-button>
            <b-button
              variant="outline filter-button-group btngreen"
              size="sm"
              @click="onToogleFilter('OUTOFSTOCK_NO')"
              :class="
                `text-bold ${
                  Object.keys(this.filterCriteria).includes('OUTOFSTOCK_NO') &&
                  this.filterCriteria['OUTOFSTOCK_NO'] === true
                    ? 'filteractive'
                    : ''
                }`
              "
            >
              C
            </b-button>
            <b-button
              variant="outline filter-button-group btnred"
              size="sm"
              @click="onToogleFilter('OUTOFSTOCK_YES')"
              :class="
                `text-bold ${
                  Object.keys(this.filterCriteria).includes('OUTOFSTOCK_YES') &&
                  this.filterCriteria['OUTOFSTOCK_YES'] === true
                    ? 'filteractive'
                    : ''
                }`
              "
            >
              C
            </b-button>
            <b-button
              variant="outline filter-button-group btnblue"
              size="sm"
              @click="
                onToogleFilter('WITHRETURNZERO_SALEGREATERZERO_INLAST2MONTHS')
              "
              :class="
                `${
                  Object.keys(this.filterCriteria).includes(
                    'WITHRETURNZERO_SALEGREATERZERO_INLAST2MONTHS'
                  ) &&
                  this.filterCriteria[
                    'WITHRETURNZERO_SALEGREATERZERO_INLAST2MONTHS'
                  ] === true
                    ? 'filteractive'
                    : ''
                }`
              "
            >
              <b-icon icon="flag-fill"></b-icon>
            </b-button>
            <b-button
              variant="outline filter-button-group btngreen"
              size="sm"
              @click="
                onToogleFilter('PRIORITY_YESORNO_WITHSALE_ZERO_INLAST2MONTHS')
              "
              :class="
                `${
                  Object.keys(this.filterCriteria).includes(
                    'PRIORITY_YESORNO_WITHSALE_ZERO_INLAST2MONTHS'
                  ) &&
                  this.filterCriteria[
                    'PRIORITY_YESORNO_WITHSALE_ZERO_INLAST2MONTHS'
                  ] === true
                    ? 'filteractive'
                    : ''
                }`
              "
            >
              <b-icon icon="flag-fill"></b-icon>
            </b-button>
            <b-button
              variant="outline filter-button-group btnyellow"
              size="sm"
              @click="onToogleFilter('RETURN_GREATERTHAN_TENPERCENT')"
              :class="
                `${
                  Object.keys(this.filterCriteria).includes(
                    'RETURN_GREATERTHAN_TENPERCENT'
                  ) &&
                  this.filterCriteria['RETURN_GREATERTHAN_TENPERCENT'] === true
                    ? 'filteractive'
                    : ''
                }`
              "
            >
              <b-icon icon="flag-fill"></b-icon>
            </b-button>
          </b-button-group>
          <b-form-input
            class="mb-2"
            style="height: 31px; max-width: 200px;"
            placeholder="Filter by description"
            v-model="filterCriteria['searchByDescription']"
            @input="onChangeFilterbyDescription"
          ></b-form-input>
        </div>
        <div>&nbsp;</div>
      </div>
      <v-card>
        <v-divider class="mx-4 my-4"></v-divider>
        <v-card-text
          class="text--primary"
          v-if="productsForSales && productsForSales.length > 0"
        >
          <template v-for="(productItem, proKey) in productsForSales">
            <div
              class="product_wrapper mb-2"
              :key="`sales_product_item_${proKey}`"
              v-if="productItem.isVisible"
            >
              <div
                class="d-flex justify-content-between align-center titlebar mb-2"
              >
                <span class="title">
                  {{ formatProductTitle(productItem) }}
                </span>
                <span class="mr-2 price pl-1">
                  Uds x Caja:
                  <span class="text-bold">{{ productItem.units_per_box }}</span>
                  &nbsp;&nbsp;&nbsp; {{ $t("Price") }} x Ud:
                  <span class="text-bold">
                    {{
                      numberFormatEsCustomDecimalCount(
                        productItem.assortment_product.unit_price_without_vat,
                        3
                      )
                    }}
                    €
                  </span>
                </span>
              </div>

              <div
                class="d-flex justify-content-center flex-wrap contentswrapper"
              >
                <div v-if="productItem.static" class="imagewrapper mx-1">
                  <p class="content_title">{{ $t("Photo") }}</p>
                  <div class="d-flex flex-column">
                    <img
                      v-if="productItem.static"
                      :src="getThumbnailUri(productItem.static)"
                      @click="onItemShowImageDialog(productItem)"
                      style="cursor: pointer;"
                    />
                  </div>
                </div>
                <div class="prioritywrapper mx-1">
                  <p class="content_title">Prio</p>
                  <div class="d-flex flex-column">
                    <div class="priority_label">
                      <div class="priority_cell">
                        {{ productItem.priority_label }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="unitsaleswrapper mx-1">
                  <div class="d-flex flex-column units_item">
                    <p class="content_title">{{ $t("last 2 months") }}</p>
                    <div class="value_group">
                      <div class="value_item">
                        <div class="value_cell cellgrey">
                          {{ productItem?.calcedlast2Months?.salesAmount }}
                        </div>
                        <div class="value_detail">{{ $t("Units") }}</div>
                      </div>
                      <div class="value_item">
                        <div class="value_cell cellgrey">
                          {{ calculateChange(productItem) }}
                        </div>
                        <div class="value_detail">%{{ $t("Change") }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column units_item">
                    <p class="content_title text-center">
                      {{ $t("Last Order") }}: *
                    </p>
                    <div class="value_group">
                      <div class="value_item">
                        <div class="value_cell">
                          {{ productItem?.calcedprev3Visit?.salesAmount }}
                        </div>
                        <div class="value_detail">UV-3</div>
                      </div>
                      <div class="value_item">
                        <div class="value_cell">
                          {{ productItem?.calcedprev2Visit?.salesAmount }}
                        </div>
                        <div class="value_detail">UV-2</div>
                      </div>
                      <div class="value_item">
                        <div class="value_cell">
                          {{ productItem?.calcedprev1Visit?.salesAmount }}
                        </div>
                        <div class="value_detail">UV-1</div>
                      </div>
                      <div class="value_item">
                        <div class="value_cell">
                          {{ productItem?.calcedlastVisit?.salesAmount }}
                        </div>
                        <div class="value_detail">UV</div>
                      </div>
                      <div class="value_item">
                        <div class="value_cell cellpink">
                          {{ productItem?.calcedlastVisit?.returnsAmount }}
                        </div>
                        <div class="value_detail">UC</div>
                      </div>
                      <div class="value_item">
                        <div class="value_cell borderR cellwhite">
                          {{ productItem.outOfStock }}
                        </div>
                        <div class="value_detail">Corte</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="inputwrapper mx-1">
                  <p class="content_title">{{ $t("Current Order") }}</p>
                  <div
                    class="d-flex justify-content-center align-center input_group"
                  >
                    <div class="input_item">
                      <b-form-input
                        :class="
                          `input_field ${
                            isNaN(productItem.salesAmount)
                              ? 'validation_error'
                              : ''
                          }`
                        "
                        v-model="productItem.salesAmount"
                        @change="
                          onChangeSalesAmount(productItem, 'SALESAMOUNT')
                        "
                        :readonly="isReadOnlyInputs(productItem)"
                      ></b-form-input>
                      <div class="input_detail">{{ $t("Units") }}</div>
                    </div>
                    <div
                      class="input_item"
                      v-if="productItem.returns_accepted === 'Yes'"
                    >
                      <b-form-input
                        :class="
                          `input_field ${
                            isNaN(productItem.returnsAmount)
                              ? 'validation_error'
                              : ''
                          }`
                        "
                        v-model="productItem.returnsAmount"
                        @change="
                          onChangeSalesAmount(productItem, 'RETURNSAMOUNT')
                        "
                        :readonly="isReadOnlyInputs(productItem)"
                      ></b-form-input>
                      <div class="input_detail">{{ $t("Change") }}</div>
                    </div>
                  </div>
                </div>
                <div class="flagwrapper">
                  <p>&nbsp;</p>
                  <b-button
                    variant="outline filter-button-group btnblue"
                    size="sm"
                    v-if="isVisibleFlagsBlue(productItem)"
                  >
                    <b-icon icon="flag-fill"></b-icon>
                  </b-button>
                  <b-button
                    variant="outline filter-button-group btngreen"
                    size="sm"
                    v-if="isVisibleFlagsGreen(productItem)"
                  >
                    <b-icon icon="flag-fill"></b-icon>
                  </b-button>
                  <b-button
                    variant="outline filter-button-group btnyellow"
                    size="sm"
                    v-if="isVisibleFlagsYellow(productItem)"
                  >
                    <b-icon icon="flag-fill"></b-icon>
                  </b-button>
                </div>
              </div>
            </div>
          </template>
        </v-card-text>
        <v-card-text class="text--primary" v-else>
          <b-alert show variant="info" class="mt-4">
            Empty data...
          </b-alert>
        </v-card-text>
        <v-divider class="mx-4 my-4"></v-divider>
      </v-card>

      <div class="border p-0 mt-2 sales_save_wrapper">
        <v-row class="sales_save_wrapper_row">
          <v-col cols="12" xs="12" sm="4" md="3" class="sales_statistics_item">
            <div class="calc_label">UV/UV-1</div>
            <div class="calc_value">
              {{
                prevVisitTotalData?.lastVisit &&
                  currency_format(prevVisitTotalData?.lastVisit.priceWithoutVat)
              }}
              /
              {{
                prevVisitTotalData?.prev1Visit &&
                  currency_format(
                    prevVisitTotalData?.prev1Visit.priceWithoutVat
                  )
              }}
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="4" md="3" class="sales_statistics_item">
            <div class="calc_label">{{ $t("Change") }}</div>
            <div class="calc_value">
              {{ total_cambio }} / {{ total_cambio_uds }}
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="4" md="3" class="sales_statistics_item">
            <div class="calc_label">{{ $t("Total") }}</div>
            <div class="calc_value">
              {{ currency_format(total_price_without_vat) }} /
              {{ currency_format(total_price_including_vat) }}
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="4" md="2" class="sales_statistics_item">
            <div class="calc_label">Plan Semana</div>
            <div class="calc_value">
              {{ currency_format(calcedTotalObjective) }}
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="4" md="1" class="sales_statistics_item">
            <b-button
              class="btn_save_sales"
              variant="success"
              size="sm"
              @click="onClickSaveSales"
            >
              <b-spinner v-if="isSaving" label="Spinning"></b-spinner>
              <span v-else>OK</span>
            </b-button>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import OfflineService from "@/services/offlineService";
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import { logInfo, logError } from "@/utils";

export default {
  name: "worksessionSales",
  components: {},
  data: function() {
    return {
      sessionId: null,
      worksessionPosId: null,
      worksessionPosBrandId: null,
      posSession: null,
      worksessionPosBrands: [],
      worksession: null,
      worksessionPos: null,
      worksessionPosBrand: null,
      selectedWorksessionPosBrandItem: null,

      salesData: null,
      productsForSales: [],
      calcedTotalObjective: 0,
      lastSalesData: null,
      brandsForSales: [],
      sortByOptions: [
        { label: this.$t("brands.brand"), value: "brandName" },
        { label: this.$t("brands.sub_brand"), value: "proSubBrandName" },
        { label: this.$t("brands.family"), value: "proSubFamilyName" },
        {
          label: `${this.$t("brands.Sales")} ${this.$t("last 2 months")}`,
          value: "calcedlast2MonthsSalesAmount"
        },
        {
          label: `${this.$t("brands.Sales")} ${this.$t("lastVisit")}`,
          value: "calcedlastVisitSalesAmount"
        },
        {
          label: `${this.$t("Change")} ${this.$t("last 2 months")}`,
          value: "calcedlast2MonthsChangedAmount"
        },
        {
          label: `${this.$t("Change")} ${this.$t("lastVisit")}`,
          value: "calcedlastVisitChangedAmount"
        }
      ],
      prevVisitTotalData: {
        lastVisit: null,
        prev1Visit: null
      },
      isSaving: false,
      isLoading: false,

      showImageDialog: false,
      selected_promatItem: null,
      selected_image: null,

      filterCriteria: {
        searchByDescription: "",
        searchByBrandId: []
      },
      selectedSortOptions: [],

      isLoadingSpinner: false
    };
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myroute", ["isEndedSession"]),
    total_cambio() {
      const { sum_sales, sum_returns } = this.getSumOfValues();
      let cambio_value = 0;
      if (sum_sales !== 0) {
        cambio_value = (sum_returns / sum_sales) * 100;
      }
      return `${parseInt(cambio_value)}%`;
    },
    total_cambio_uds() {
      const { sum_returns } = this.getSumOfValues();
      return `${sum_returns}`;
    },
    total_price_without_vat() {
      let total_price = 0;
      if (this.productsForSales && this.productsForSales.length > 0) {
        this.productsForSales.map(el => {
          let { salesAmount, returnsAmount, unit_price_without_vat } = el;
          unit_price_without_vat = this.convertFloatValue(
            unit_price_without_vat
          );
          salesAmount = this.convertIntValue(salesAmount);
          returnsAmount = this.convertIntValue(returnsAmount);
          total_price += unit_price_without_vat * (salesAmount - returnsAmount);
        });
      }
      return total_price;
    },
    total_price_including_vat() {
      let total_price = 0;
      if (this.productsForSales && this.productsForSales.length > 0) {
        this.productsForSales.map(el => {
          let {
            salesAmount,
            returnsAmount,
            unit_price_without_vat,
            vat_code
          } = el;
          unit_price_without_vat = this.convertFloatValue(
            unit_price_without_vat
          );
          salesAmount = this.convertIntValue(salesAmount);
          returnsAmount = this.convertIntValue(returnsAmount);
          vat_code = this.convertFloatValue(vat_code);
          if (unit_price_without_vat !== 0) {
            total_price +=
              unit_price_without_vat *
              (salesAmount - returnsAmount) *
              (1 + vat_code * 0.01);
          }
        });
      }
      return total_price;
    }
  },
  methods: {
    moment,
    calculateChange(productItem) {
      let ret = null;
      if (productItem?.calcedlast2Months?.salesAmount != 0) {
        ret = parseInt(
          (productItem?.calcedlast2Months?.returnsAmount * 100) /
            productItem?.calcedlast2Months?.salesAmount
        );
      } else if (productItem?.calcedlast2Months?.returnsAmount != 0) {
        ret = 100;
      }
      ret = ret !== null ? `${ret}%` : "";
      return ret;
    },
    isReadOnlyInputs(productItem) {
      if (this.isEndedSession) return true;
      if (
        productItem?.salesType &&
        (productItem.salesType == "NONE" || productItem.salesType == "IMPORT")
      )
        return true;
      if (productItem?.isImportedValues && productItem?.foundImportedSales)
        return true;
      return false;
    },
    isVisibleFlagsYellow(productItem) {
      if (
        productItem?.calcedlast2Months?.salesAmount !== 0 &&
        productItem?.calcedlast2Months?.returnsAmount /
          productItem?.calcedlast2Months?.salesAmount >
          0.1
      ) {
        return true;
      }
      return false;
    },
    isVisibleFlagsGreen(productItem) {
      const { priority_1, priority_2 } = productItem.assortment_product;
      if (
        (priority_1 == "Yes" || priority_2 == "Yes") &&
        productItem?.calcedlast2Months?.salesAmount === 0
      ) {
        return true;
      }
      return false;
    },
    isVisibleFlagsBlue(productItem) {
      // const { priority_1 } = productItem.assortment_product;
      if (
        // priority_1 === "Yes" &&
        productItem?.calcedlast2Months?.returnsAmount === 0 &&
        productItem?.calcedlast2Months?.salesAmount > 0
      ) {
        return true;
      }
      return false;
    },
    onChangeSortByOptions() {
      this.init();
    },
    onToogleFilter(filterKey) {
      console.log("filterKey - ", filterKey);
      if (!Object.keys(this.filterCriteria).includes(filterKey)) {
        this.filterCriteria[filterKey] = true;
      } else {
        this.filterCriteria[filterKey] = !this.filterCriteria[filterKey];
      }
      this.onChangeFilter();
    },
    onChangeFilterbyDescription() {
      this.onChangeFilter();
    },
    onChangeFilterbyBrandId() {
      this.onChangeFilter();
    },
    onChangeFilter() {
      if (this.productsForSales && this.productsForSales.length > 0) {
        this.productsForSales = this.productsForSales.map(el => {
          let pushItem = { ...el };
          let isVisible = true;

          for (const [filterKey, isFilter] of Object.entries(
            this.filterCriteria
          )) {
            let isForItemVisible = true;
            if (isFilter) {
              switch (filterKey) {
                case "ONLYSALES_INLAST2MONTHS":
                  if (el.last2Months.length === 0) {
                    isForItemVisible = false;
                  }
                  break;
                case "ONLYRETURNS_INCURRENTCHART":
                  if (el.returnsAmount <= 0) {
                    isForItemVisible = false;
                  }
                  break;
                case "WITHSALE_ZERO_INCURRENTCHART":
                  if (el.salesAmount > 0) {
                    isForItemVisible = false;
                  }
                  break;
                case "WITHSALE_GREATERZERO_INCURRENTCHART":
                  if (el.salesAmount <= 0) {
                    isForItemVisible = false;
                  }
                  break;
                case "WITHRETURNZERO_SALEGREATERZERO_INLAST2MONTHS":
                  if (el?.calcedlast2Months) {
                    const { returnsAmount, salesAmount } = el.calcedlast2Months;
                    if (returnsAmount !== 0 || salesAmount <= 0) {
                      isForItemVisible = false;
                    }
                  }
                  break;
                case "PRIORITY_YESORNO_WITHSALE_ZERO_INLAST2MONTHS":
                  if (el?.calcedlast2Months && el?.assortment_product) {
                    const { salesAmount } = el.calcedlast2Months;
                    const { priority_1, priority_2 } = el.assortment_product;
                    if (
                      !(
                        (priority_1 === "Yes" || priority_2 === "Yes") &&
                        salesAmount === 0
                      )
                    ) {
                      isForItemVisible = false;
                    }
                  }
                  break;
                case "RETURN_GREATERTHAN_TENPERCENT":
                  if (el?.calcedlast2Months) {
                    const { salesAmount, returnsAmount } = el.calcedlast2Months;
                    if (
                      !salesAmount ||
                      salesAmount === 0 ||
                      returnsAmount / salesAmount < 0.1
                    ) {
                      isForItemVisible = false;
                    }
                  }
                  break;
                case "ONLYNOVELTY":
                  if (el.novelty !== 1) {
                    isForItemVisible = false;
                  }
                  break;
                case "PRIORITY_YESANDNO":
                  if (el.assortment_product) {
                    const { priority_1, priority_2 } = el.assortment_product;
                    if (priority_1 !== "Yes" && priority_2 !== "Yes") {
                      isForItemVisible = false;
                    }
                  }
                  break;
                case "OUTOFSTOCK_NO":
                  if (el.outOfStock === 1) {
                    isForItemVisible = false;
                  }
                  break;
                case "OUTOFSTOCK_YES":
                  if (el.outOfStock !== 1) {
                    isForItemVisible = false;
                  }
                  break;
                case "searchByBrandId":
                  if (isFilter.length > 0 && !isFilter.includes(el.brandId)) {
                    isForItemVisible = false;
                  }
                  break;
                case "searchByDescription":
                  {
                    let filterItems = [];
                    filterItems.push(el.name);
                    // filterItems.push(el.description);
                    filterItems.push(el.brandName);
                    filterItems.push(el.proSubBrandName);
                    let foundItems = filterItems.filter(
                      stringItem =>
                        stringItem !== null &&
                        stringItem
                          .toLowerCase()
                          .includes(isFilter.toLowerCase())
                    );
                    if (foundItems.length === 0) {
                      isForItemVisible = false;
                    }
                  }
                  break;
              }
            }
            isVisible = isVisible && isForItemVisible;
          }
          pushItem.isVisible = isVisible;
          return pushItem;
        });
      }
    },
    convertFloatValue(val) {
      let parseval = parseFloat(val);
      if (isNaN(parseval)) parseval = 0;
      return parseval;
    },
    convertIntValue(val) {
      let parseval = parseInt(val);
      if (isNaN(parseval)) parseval = 0;
      return parseval;
    },
    getSumOfValues() {
      let sum_sales = 0;
      let sum_returns = 0;
      if (this.productsForSales && this.productsForSales.length > 0) {
        this.productsForSales.map(el => {
          let { salesAmount, returnsAmount } = el;
          salesAmount = this.convertIntValue(salesAmount);
          returnsAmount = this.convertIntValue(returnsAmount);
          sum_sales += salesAmount;
          sum_returns += returnsAmount;
        });
      }
      return { sum_sales, sum_returns };
    },
    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },
    getImageUri(image) {
      return ApiService.getImageUri(image);
    },
    formatProductTitle(productItem) {
      let title = [];
      if (productItem?.brand) {
        title.push(productItem.brandName);
      }
      if (
        productItem?.productSubBrand &&
        productItem.productSubBrand.length > 0
      ) {
        title.push(productItem.proSubBrandName);
      }
      if (!_.isEmpty(productItem.name)) {
        title.push(productItem.name);
      }
      return title.join(" - ");
    },
    onItemShowImageDialog(productItem) {
      this.selected_promatItem = productItem;
      let thumbnail_url = this.getImageUri(productItem.static);
      this.selected_image = thumbnail_url;
      this.showImageDialog = true;
    },
    saveOfflineRealTimeSalesData(formBody) {
      this.$store.dispatch("myroute/setIsOffline", true);
      OfflineService.saveOfflineRealTimeSalesData(formBody);
    },
    async onChangeSalesAmount(productItem, changeKey) {
      let worksessionPosBrandId = this.worksessionPosBrand.id;
      const {
        assortmentId,
        operatorId,
        productId,
        salesAmount,
        returnsAmount,
        salesDate,
        unit_price_without_vat,
        vat_code
      } = productItem;
      let formData = {
        worksessionPosBrandId,
        assortmentId,
        operatorId,
        productId,
        salesAmount,
        returnsAmount,
        salesDate,
        unit_price_without_vat,
        vat_code,
        operatorCode: _.get(
          productItem,
          "assortment_product.id_product_operator"
        )
      };
      try {
        let response = await ApiService.postNotAlert(
          `worksession/sales/realtimesavesales`,
          {
            formData
          }
        );
        if (!response?.success) {
          logError(this.$t("Failed"));
        }
      } catch (error) {
        console.log("error - ", error);
        this.saveOfflineRealTimeSalesData(formData);
      }
    },
    goToNextPage() {
      this.$router.push({
        name: "router_worksessionpospage",
        params: {
          id: this.sessionId,
          worksessionPosId: this.worksessionPosId,
          frompage: "survey",
          brandId: this.worksessionPosBrand?.brandId
        }
      });
    },
    async onClickSaveSales() {
      if (this.isEndedSession) {
        this.goToNextPage();
        return;
      }
      if (this.total_price_without_vat <= this.calcedTotalObjective) {
        if (
          !window.confirm(
            `la venta es de ${this.total_price_without_vat} € y es inferior al plan que es de ${this.calcedTotalObjective} €. ¿Quieres continuar?`
          )
        ) {
          return;
        }
      }
      let worksessionPosBrandId = this.worksessionPosBrand.id;
      let saveSalesData = [];
      for (const productItem of this.productsForSales) {
        const {
          assortmentId,
          operatorId,
          productId,
          assortment_product,
          salesDate,
          salesAmount,
          returnsAmount,
          unit_price_without_vat,
          productSubBrand,
          productSubFamily,
          familyId,
          vat_code
        } = productItem;

        const subBrandId = productSubBrand[0]?.brand_id ?? null;
        const subFamilyId = productSubFamily[0]?.family_id ?? null;
        const sub2FamilyId =
          productSubFamily[0]?.children[0]?.family_id ?? null;

        if (isNaN(salesAmount) || isNaN(returnsAmount)) {
          logError("Error de entrada: ¡debe ingresar solo números!");
          return;
        }
        let pushItem = {
          salesDate,
          worksessionPosBrandId,
          operatorId,
          productId,
          assortmentId,
          salesAmount,
          returnsAmount,
          unit_price_without_vat,
          vat_code,
          familyId,
          subFamilyId,
          sub2FamilyId,
          subBrandId,
          operatorCode: _.get(
            productItem,
            "assortment_product.id_product_operator"
          )
        };
        saveSalesData.push(pushItem);
      }
      try {
        this.isSaving = true;
        this.isLoadingSpinner = true;
        let response = await ApiService.postNotAlert(
          `worksession/sales/savesales`,
          {
            saveSalesData,
            worksessionPosId: this.worksessionPosId
          }
        );
        if (response?.success) {
          logInfo(this.$t("Success"));
          this.init();
        }
        this.goToNextPage();
        this.isSaving = false;
        this.isLoadingSpinner = false;
      } catch (error) {
        console.log("error - ", error);
        logError("Network error !");
        this.saveOfflineSalesAllData(saveSalesData);
        this.isSaving = false;
        this.isLoadingSpinner = false;
      }
    },
    saveOfflineSalesAllData(formBody) {
      this.$store.dispatch("myroute/setIsOffline", true);
      OfflineService.saveOfflineSalesAllData(formBody);
      // redirect offline page !
      this.$router.push({
        name: "router_offline_page"
      });
    },
    generatePrevVisitTotalData(lastVisit, controlKey) {
      if (lastVisit && lastVisit.length > 0) {
        let calcedLastVisit = { salesDate: "", priceWithoutVat: 0 };
        lastVisit.map(el => {
          let {
            salesAmount,
            returnsAmount,
            salesDate,
            unit_price_without_vat
          } = el;
          salesAmount = this.convertIntValue(salesAmount);
          returnsAmount = this.convertIntValue(returnsAmount);
          unit_price_without_vat = this.convertFloatValue(
            unit_price_without_vat
          );
          calcedLastVisit.salesDate = salesDate;
          calcedLastVisit.priceWithoutVat +=
            unit_price_without_vat * (salesAmount - returnsAmount);
        });
        this.prevVisitTotalData[controlKey] = calcedLastVisit;
      }
    },
    checkHasSurveys() {
      let isHasSurveys = false;
      if (
        this.selectedWorksessionPosBrandItem &&
        this.selectedWorksessionPosBrandItem?.surveys &&
        this.selectedWorksessionPosBrandItem?.surveys.length > 0
      ) {
        isHasSurveys = true;
      }
      return isHasSurveys;
    },
    onClickBackButton() {
      const { id, worksessionId } = this.worksessionPos;
      const { brandId } = this.worksessionPosBrand;
      let worksessionPosId = id;
      let isHasSurveys = this.checkHasSurveys();
      if (isHasSurveys) {
        // go to survey page
        this.$router.push({
          name: "router_worksessionpos_surveyv2",
          params: {
            id: worksessionId,
            worksessionPosId,
            brandId
          }
        });
      } else {
        // go to pos page
        this.$router.push({
          name: "router_worksessionpospage",
          params: {
            id: worksessionId,
            worksessionPosId,
            frompage: "route"
          }
        });
      }
    },
    async init() {
      this.isLoading = true;
      try {
        const {
          id,
          worksessionPosId,
          worksessionPosBrandId
        } = this.$route.params;
        this.sessionId = id;
        this.worksessionPosId = worksessionPosId;
        this.worksessionPosBrandId = worksessionPosBrandId;

        const [posResponse, salesResponse] = await Promise.all([
          ApiService.get(`worksession/pos/${this.worksessionPosId}`),
          ApiService.post(
            `worksession/sales/initializeSales/${this.worksessionPosBrandId}`,
            { selectedSortOptions: this.selectedSortOptions }
          )
        ]);

        const { data, reasonTypes, worksessionPosBrands } = posResponse;

        this.posSession = data;
        this.worksessionPosBrands = worksessionPosBrands;
        this.salesData = [];

        this.filterCriteria = {
          searchByDescription: "",
          searchByBrandId: []
        };
        this.productsForSales = [];
        this.prevVisitTotalData = {
          lastVisit: null,
          prev1Visit: null
        };

        const {
          worksession,
          worksessionPos,
          worksessionPosBrand,
          productsForSales,
          calcedTotalObjective,
          lastSalesData,
          brandsForSales
        } = salesResponse;

        this.worksession = worksession;
        this.worksessionPos = worksessionPos;
        this.worksessionPosBrand = worksessionPosBrand;
        this.productsForSales = productsForSales;
        this.calcedTotalObjective = calcedTotalObjective;
        this.lastSalesData = lastSalesData;
        this.brandsForSales = brandsForSales;
        let { lastVisit, prev1Visit, prev2Visit, prev3Visit } = lastSalesData;
        this.generatePrevVisitTotalData(lastVisit, "lastVisit");
        this.generatePrevVisitTotalData(prev1Visit, "prev1Visit");
        if (this.worksessionPosBrand && this.worksessionPosBrand?.brand) {
          let foundSessionBrandItem = this.worksessionPosBrands.find(
            el => el.id === this.worksessionPosBrand.brandId
          );
          if (foundSessionBrandItem) {
            this.selectedWorksessionPosBrandItem = foundSessionBrandItem;
          }
          if (this.productsForSales.length > 0) {
            let brandIds = this.productsForSales.map(el => el.brandId);
            if (brandIds.includes(this.worksessionPosBrand.brandId)) {
              this.filterCriteria.searchByBrandId.push(
                this.worksessionPosBrand.brandId
              );
            }
          }
        }
        this.onChangeFilter();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    }
  },

  async mounted() {
    await this.init();
  }
};
</script>

<style>
.brandSelectBox {
  max-width: 200px;
}
</style>
